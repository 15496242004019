<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    background-color="primary"
    transition="dialog-bottom-transition"
  >
    <v-card flat tile>
      <v-card-title>
        <v-row
          align="center"
          justify="center"
          style="height: 90vh"
          class="no-gutters"
        >
          <v-col
            cols="12"
            class="fill-height d-flex flex-column justify-center align-center my-auto"
          >
            <div>
              <v-img
                height="auto"
                :src="require('@/assets/images/' + errorJson[errorCode].src)"
                :max-width="$vuetify.breakpoint.smAndDown ? '140' : '180'"
                :min-width="$vuetify.breakpoint.smAndDown ? '130' : '170'"
                class="mx-auto my-2"
              >
              </v-img>
              <v-row no-gutters>
                <span
                  class="text-h5 font-weight-bold grey--text text--darken-4 mx-auto my-2"
                >
                  {{ errorJson[errorCode].heading }}
                </span>
              </v-row>
              <v-row class="my-2">
                <span
                  class="subtitle-2 font-weight-medium grey--text text--darken-2 mx-auto text-center"
                  style="display: block"
                  >{{ errorMessage }}
                </span>
              </v-row>
              <v-row class="my-2" v-if="workDateMessage">
                <span
                  class="subtitle-2 font-weight-medium grey--text text--darken-2 mx-auto text-center"
                  style="display: block"
                >
                  {{ workDateMessage }}
                </span>
              </v-row>
              <v-row class="mt-4 mb-1">
                <span
                  class="subtitle-2 font-weight-regular grey--text text--darken-2 mx-auto text-center"
                  style="display: block"
                  v-html="errorJson[errorCode].message"
                >
                </span>
              </v-row>
              <v-row class="my-10">
                <v-btn
                  class="primary text-none rounded-lg elevation-0 mx-auto"
                  width="140"
                  depressed
                  @click="$emit('ticketScreen')"
                >
                  <span class="subtitle-2 font-weight-regular mx-auto my-2">
                    {{ $t("goToTickets") }}
                  </span>
                </v-btn>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
  </v-dialog>
</template>
<script>
import errorScreenJSON from "@/static/errorScreens.json";
export default {
  props: {
    errorCode: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: true,
      errorJson: errorScreenJSON,
    };
  },
  computed: {
    workDateMessage() {
      if (
        this.errorMessage &&
        this.errorMessage.includes("work_date cannot be after")
      ) {
        return "Please change work date.";
      }
      return "";
    },
  },
};
</script>
